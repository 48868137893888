import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 获取Brands数据
 * @param params
 * @returns {Promise<*>}
 */
export const getBrandsListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/keywords/brand/get/all'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 获取Category数据
 * @param params
 * @returns {Promise<*>}
 */
export const getCategoryListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/keywords/category/get/all?cid1='+params.cid1))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 获取Brands 详情 数据
 * @param params
 * @returns {Promise<*>}
 */
export const getBrandsInfoService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/keywords/brand/get/list?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 获取Category 详情 数据
 * @param params
 * @returns {Promise<*>}
 */
export const getCategoryInfoService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/keywords/category/get/list?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 添加品牌数据
 * @param params
 * @returns {Promise<*>}
 */
export const addBrandsService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/api/keywords/brand'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 添加分类数据
 * @param params
 * @returns {Promise<*>}
 */
export const addCategoryService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/api/keywords/category'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 删除品牌数据
 * @param params
 * @returns {Promise<*>}
 */
export const delBrandsService = async (params) => {
    try {
        const { data } = await request.delete(evnUrl.concat('/api/keywords/brand?ids='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 删除分类数据
 * @param params
 * @returns {Promise<*>}
 */
export const delCategoryService = async (params) => {
    try {
        const { data } = await request.delete(evnUrl.concat('/api/keywords/category?ids='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
