/**
 * 关键字数据集
 * @type {({})[]}
 */
export const keywordsList = [
    {
        value:1,
        label:'Brands',
    },
    {
        value:2,
        label:'Category',
    },
]

/**
 * brands数据
 * @type {*[]}
 */
export const brandsColumns = [
    {
        title: 'Brands',
        width:200,
        key: 'name'
    },
    {
        title: 'Keywords',
        key:'keywords',
    },
    {
        title:' ',
        width:120,
        slot:'btn',
    }
]

/**
 * Category数据
 * @type {*[]}
 */
export const categoryColumns = [
    {
        title: 'Category',
        width:200,
        key: 'name'
    },
    {
        title: 'Keywords',
        key:'keywords',
    },
    {
        title:' ',
        width:120,
        slot:'btn',
    }
]
