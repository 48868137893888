/**
 * 关键词详情
 */
import Vue from 'vue'
import { Message,Modal,Page,Input,DatePicker,locale,Table,Button,Icon } from 'iview';
import lang from 'iview/dist/locale/en-US';
locale(lang)
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('DatePicker', DatePicker);
Vue.component('Table', Table);
Vue.component('Button', Button);
Vue.component('Icon', Icon);
import { getBrandsInfoService,getCategoryInfoService,addBrandsService,addCategoryService,delBrandsService,delCategoryService  } from '@/service/keywords-service'
import { formatUnit } from "@/utils/tools";
import Header from '@/components/Header/header.vue'
import { mapMutations, mapState } from "vuex";
const KeywordsEdit = {
    name:'KeywordsEdit',
    components:{
        Header,
    },
    computed: {
        ...mapState({
            launchId: state => state.launch.launchId,  //获取状态管理投放id
            launchTitle: state => state.launch.launchTitle,  //获取状态管理投放标题
            launchName: state => state.launch.launchName,  //获取状态管理投放名称
            typeId: state => state.launch.typeId,  //获取状态管理分类id
            launchTab: state => state.launch.launchTab,  //获取状态管理分类tab
            selectList: state => state.product.selectList, //获取状态管理选择的商品
        }),
    },
    data() {
        return {
            loadingStatus:false,
            formatUnit:formatUnit,
            editObj:[], //编辑数据集
            addStatus:false,
            keyWords:'',
        }
    },
    created() {
        if(this.launchTab == 1){
            this.onBrandsInfo().then()
        }
        if(this.launchTab == 2){
            this.onCategoryInfo().then()
        }
    },
    methods: {
        ...mapMutations('product',['SET_SELECT_LIST']),
        /**
         * 返回至列表页
         */
        onBack() {
            this.$router.back()
        },
        /**
         * 提交数据
         */
        onEditSubmit(){
            if(this.keyWords === ''){
                Message.error('Please enter')
                return;
            }
            if(this.launchTab == 1){
                this.onAddBrands().then()
            }
            if(this.launchTab == 2){
                this.onAddCategory().then()
            }
        },
        onDelConfirm(id) {
            Modal.confirm({
                title: 'Tips',
                content: '<p>Please confirm.</p>',
                onOk: () => {
                    if(this.launchTab == 1){
                        this.onBrands(id).then()
                    }
                    if(this.launchTab == 2){
                        this.onCategory(id).then()
                    }
                }
            });
        },
        /**
         * 获取Brands 详情 数据
         * @returns {Promise<void>}
         */
        async onBrandsInfo() {
            try {
                let params = {
                    id:this.launchId
                }
                const {code,data} = await getBrandsInfoService(params)
                if(code === 1){
                    this.editObj = data
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取Category 详情 数据
         * @returns {Promise<void>}
         */
        async onCategoryInfo() {
            try {
                let params = {
                    id:this.launchId
                }
                const {code,data} = await getCategoryInfoService(params)
                if(code === 1){
                    this.editObj = data
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 添加品牌
         * @returns {Promise<void>}
         */
        async onAddBrands() {
            try {
                this.loadingStatus = true
                let params = {
                    brandId:this.launchId,
                    keyword:this.keyWords
                }
                const { code } = await addBrandsService(params)
                if(code === 1){
                    this.onBrandsInfo().then()
                    this.keyWords = ''
                    this.addStatus = false
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 添加分类
         * @returns {Promise<void>}
         */
        async onAddCategory() {
            try {
                this.loadingStatus = true
                let params = {
                    categoryId:this.launchId,
                    keyword:this.keyWords
                }
                const { code } = await addCategoryService(params)
                if(code === 1){
                    this.onCategoryInfo().then()
                    this.keyWords = ''
                    this.addStatus = false
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 删除品牌
         * @returns {Promise<void>}
         */
        async onBrands(id) {
            try {
                let params = {
                    id:id,
                }
                const { code } = await delBrandsService(params)
                if(code === 1){
                    this.onBrandsInfo().then()
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除分类数据
         * @returns {Promise<void>}
         */
        async onCategory(id) {
            try {
                let params = {
                    id:id,
                }
                const { code } = await delCategoryService(params)
                if(code === 1){
                    this.onCategoryInfo().then()
                }
            }catch (error){
                console.log(error)
            }
        },
    },
}
export default KeywordsEdit
