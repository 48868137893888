<template>
    <div class="layout-body">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->

        <!--s: Launch -->
        <div class="keywords-edit">

            <div class="edit-top">
                <span class="name">{{ launchName }}</span>
            </div>

           <div class="keywords-list">
               <div class="box">
                   <div class="title">
                       <span style="float:left;">Relative</span>
                       <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Regular</span>
                   </div>
                   <div class="input" :key="key" v-for="(edit,key) in editObj">
                       <div class="text">
                          {{ edit.keyword }}
                       </div>
                       <a href="javascript:" class="search-tab-add">
                           <Icon custom="search-remove" @click="onDelConfirm(edit.id)"></Icon>
                           <Icon custom="search-add" v-if="key === editObj.length-1" @click="addStatus = true"></Icon>
                       </a>
                   </div>
                   <div class="input" v-if="addStatus || editObj.length === 0">
                       <div class="text">
                           <Input v-model="keyWords" type="text"></Input>
                       </div>
                       <a href="javascript:" class="search-tab-add">
                           <Icon custom="search-remove" v-if="editObj.length !== 0" @click="addStatus = false"></Icon>
                       </a>
                   </div>
               </div>
           </div>

            <!--s: Btn-->
            <div class="launch-btn-edit">
                <div class="box">
                    <Button style="margin-left: 30px" :loading="loadingStatus" @click="onEditSubmit">Save</Button>
                </div>
            </div>
            <!--s: Btn-->

        </div>
        <!--e: Launch -->

    </div>
</template>
<script>
import SearchEdit from './edit'
export default SearchEdit
</script>
<style lang="less">
@import "edit";
</style>
